









































import {Component, Vue} from "vue-property-decorator";
import {downloadToDisk} from "@/utils/download";
import UploadError from "@/components/UploadError.vue";
import {setPageTitle} from "@/utils/meta";
import Card from "@/components/Card.vue";

@Component({components: {Card, UploadError}})
export default class PortfolioIndex extends Vue {
  dataFile: Blob | null = null;
  uploadDataPercentage = 0;
  uploadingData = false;
  error = null;

  type = ['fund'];

  created() {
    setPageTitle("Portfolio Generator");
  }

  uploadData() {
    if (!this.dataFile) {
      return;
    }

    this.uploadingData = true;
    const formData = new FormData();
    formData.append('file', this.dataFile);
    formData.append('types', this.type.join('-'));
    this.error = null;
    this.$api.post('company/simple-portfolio',
        formData,
        {
          responseType: "blob",
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: (progressEvent) => {
            this.uploadDataPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100).toString());
          }
        }
    ).then(response => {
      console.log('SUCCESS!!');
      downloadToDisk(response);

    }).catch((e) => {
      this.error = e.response.data;
    }).finally(() => {
      this.uploadingData = false;
    });
  }

}
